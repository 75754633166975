@import './src/scss/variables';
@import './src/scss/app';

.widget__options-list li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.widget__options-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: column-reverse;

    a {
      //display: none;
    }
}

.widget__options label {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.widget__dice-container {
	  display: flex;
    border: 1px solid;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-height: 12rem;

    ul {
      display: flex;
      gap: 2rem;
    }
}

.widget__options-list .widget__input-number {
    border: 1px solid;
}


.fa-dice-d100::before {
  content: "\e41c"
}


.diceroller-container {
display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.5rem;
    border: 1px solid;
    min-height: 12rem;

  .diceroller-controls {
    flex: 1 1 auto;

    ul > li {
      position: relative;
      //height: 25px;

      &:not(last-of-child) {
        //margin-bottom: 5px;
      }
    }

    .control-group {
//       left: 70px;
//       position: absolute;
//       top: 0;
//       width: 200px;

      .control-button {
        //border: 1px solid black;
        //padding: 0 10px;
      }
    }
  }

  .diceroller-results {
    flex: 1 1 auto;
    &-row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      text-align: center;

      &:not(last-of-child) {
        margin-bottom: 10px;
      }

      > div {
        &.diceroller-results-stat {
          flex: 1 1 auto;
        }
        i {
          font-size: 50px;
        }
      }
    }

    &-head {
      color: darkgray;
    }

    &-list {
    }

    &-total {
      font-size: 1.3em;
    }
  }
}

.green-box[disabled] {
  cursor: unset;
  pointer-events: none;
  background-color: lightgrey;
  color: gray;

  .md-click-circle {
    display: none;
    visibility: hidden;
  }
}

.green-box {
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;

  * {
    user-select: none;
    text-align: center;
  }

  a {
    font-weight: bold;
    color: white !important;
  }
}

.material-click {
  overflow: hidden;
  position: relative;

  .md-click-circle {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, .2);
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);

    &.md-click-animate {
      animation: mdClickEffect .65s linear;
    }
  }
}

@keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 0 !important;
    transform: scale(2.5);
  }
}